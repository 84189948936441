import { FormControl, InputLabel, MenuItem, Select, SxProps, Theme } from "@mui/material";
import React from "react";
import { useWebsiteStore } from "../../website/WebsiteStoreProvider";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";

export function WebsiteSelector(props: {
  inputRef?:     React.RefObject<HTMLInputElement>,
  defaultValue?: string,
  size?:         "small" | "medium",
  required?:     boolean, 
  fullWidth?:    boolean, 
  sx?:           SxProps<Theme>,
  extraValues?:  ReadonlyArray<string>,
  onChange?:     (event: SelectChangeEvent<string>, child: React.ReactNode) => void,
}) {
  const {websites} = useWebsiteStore();
  return (
    <FormControl size={props.size} fullWidth={props.fullWidth} sx={props.sx}>
      <InputLabel required={props.required}>Website</InputLabel>
      <Select inputRef={props.inputRef} label="Website" defaultValue={props.defaultValue} onChange={props.onChange}>
        {props.extraValues?.map(extra => <MenuItem key={extra} value={extra}>{extra}</MenuItem>)}
        {websites.map(website => <MenuItem key={website.websiteName} value={website.websiteName}>{website.websiteLabel}</MenuItem>)}
      </Select>
    </FormControl>
  );
}