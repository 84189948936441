import { SvgIcon } from "@mui/material";

// from https://app.surferseo.com/static-images/logo/surferLogo.svg
export function SurferIcon() {
    return <SvgIcon>
        <svg width="90" height="91" viewBox="-12 -12 114 114" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M68.9831 0.971985C80.4969 0.971985 89.8306 10.3057 89.8306 21.8194L89.8306 27.3153L80.5085 30.1808V21.7851C80.49 15.4356 75.337 10.294 68.9831 10.294H20.8475C14.4822 10.294 9.32209 15.4541 9.32209 21.8194V44.0486C9.32209 47.9107 9.32209 49.8417 8.26073 51.2783C7.19938 52.7149 5.3536 53.2822 1.66204 54.417L1.62175e-05 54.9278L5.15498e-05 21.8194C5.15498e-05 10.3057 9.33378 0.971985 20.8475 0.971985H68.9831Z" fill="currentColor"/>
            <path d="M88.1685 37.5614L89.8306 37.0505L89.8306 70.1245C89.8306 81.6383 80.4968 90.972 68.9831 90.972H20.8475C9.33373 90.972 0 81.6383 0 70.1245L5.82831e-06 64.6631L9.32209 61.7976V70.1585C9.34039 76.5082 14.4935 81.65 20.8475 81.65H68.9831C75.3484 81.65 80.5085 76.4898 80.5085 70.1245V47.9298C80.5085 44.0677 80.5085 42.1367 81.5699 40.7001C82.6312 39.2635 84.477 38.6962 88.1685 37.5614Z" fill="currentColor"/>
            <path d="M25.1189 60.0995V42.6931C25.1189 38.1058 26.0766 37.1483 30.6638 37.1483H32.3793C36.967 37.1483 37.9242 38.1056 37.9242 42.6931V60.0995C37.9242 64.6871 36.967 65.6444 32.3793 65.6444H30.6638C26.0766 65.6444 25.1189 64.6868 25.1189 60.0995Z" fill="currentColor"/>
            <path d="M57.4504 26.3099C52.8632 26.3099 51.9055 27.2675 51.9055 31.8548V60.0994C51.9055 64.6868 52.8632 65.6442 57.4504 65.6442H59.1659C63.7536 65.6442 64.7108 64.687 64.7108 60.0994V31.8548C64.7108 27.2672 63.7536 26.3099 59.1659 26.3099H57.4504Z" fill="currentColor"/>
        </svg>
    </SvgIcon>
}