import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './easymde.css';
import {StyledEngineProvider} from '@mui/material/styles';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {BrowserRouter} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";
import {QueryClient, QueryClientProvider} from "react-query";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const darkTheme = createTheme({
  palette: {
    background: {},
    text: {},
  },
});

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <ThemeProvider theme={darkTheme}>
        <StyledEngineProvider injectFirst>
          <App/>
        </StyledEngineProvider>
      </ThemeProvider>
    </BrowserRouter>
  </QueryClientProvider>
);
