import EasyMDE from "easymde";
import React from "react";
import {useTheme} from "@mui/material";

export function MarkdownEditor(props: {
  initialValue: string,
  onChange: (newValue: string) => void,
}) {
  const theme = useTheme();
  const [textareaRef, setTextareaRef] = React.useState<HTMLTextAreaElement | null>()

  React.useEffect(() => {
    console.log("redraw")
    if (textareaRef) {
      const instance = new EasyMDE({
        element: textareaRef,
        status: false,
        toolbar: false,
        spellChecker: false,
        initialValue: props.initialValue,
        maxHeight: "88vh",
      })

      instance.codemirror.on("change", () => {
        props.onChange(instance.value())
      })

      return () => {
        instance.toTextArea()
      }
    }
  }, [textareaRef, props])

  return (
    <div id="easy-mde-textarea-container" className={theme.palette.mode} style={{display: "block"}}>
      <textarea id="easy-mde-textarea" ref={setTextareaRef} className="dark"/>
    </div>
  )
}