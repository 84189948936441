import {useDb} from "../../common/DbProvider"
import React from "react"

export interface UpdateTimestampDao {
  put: (timestampName: string, timestamp: number) => Promise<void>
  get: (timestampName: string) => Promise<number | undefined>
}

export function useUpdateTimestampDao(): UpdateTimestampDao {
  const {db} = useDb()

  return React.useMemo(() => {
    console.debug("Creating UpdateTimestampDao")

    return ({
      put: (timestampName: string, timestampValue: number) => {
        const tx = db.transaction(["update-timestamps"], "readwrite")
        const updateTimestampStore = tx.objectStore("update-timestamps")

        return (
          updateTimestampStore.put(timestampValue, timestampName)
            .then(_ => {
            })
            .finally(() => {
              return tx.done
            })
        )
      },

      get: (timestampName: string) => {
        const tx = db.transaction(["update-timestamps"], "readonly")
        const updateTimestampStore = tx.objectStore("update-timestamps")

        return (
          updateTimestampStore
            .get(timestampName)
            .finally(() => {
              return tx.done
            })
        )
      }
    })
  }, [db])
}