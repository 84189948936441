import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  Stack
} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers";
import React from "react";
import {useContentStore} from "../../../ContentStoreProvider";
import Models from "../../../api/Models";
import dayjs, {Dayjs} from "dayjs";
import {useSnackbar} from "../../../../common/SnackbarProvider";

export function PublishDialog(props: {
  contentId: string,
  isOpen: boolean,
  setIsOpen: (_: boolean) => void,
}) {
  const {publishContent} = useContentStore();
  const snackbar = useSnackbar();

  const [selectedValue, setSelectedValue] = React.useState<"now" | "later">("now");
  const [publishTime, setPublishTime] = React.useState<Dayjs | null>(null);

  const handleRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectedValue = event.target.value;
    if (newSelectedValue === "now" || newSelectedValue === "later") {
      setSelectedValue(newSelectedValue);
    }
  };

  const handlePublish = () => {
    if (selectedValue === "later" && publishTime === null) {
      return; // do nothing
    }

    const command: Models.PublishContentCommand = {
      contentId: props.contentId,
      customPublishTime: selectedValue === "later" ? publishTime!.valueOf() : undefined,
    };
    publishContent(command)
      .then(_ => {
        snackbar.showSuccess("Content is successfully published");
        props.setIsOpen(false);
      })
      .catch(error => {
        snackbar.showError(`Error: ${error.message}`);
      });
  };

  const handleCancel = () => {
    props.setIsOpen(false);
  }

  return (
    <Dialog fullWidth open={props.isOpen} maxWidth="sm">
      <DialogTitle>Publish Content</DialogTitle>
      <DialogContent>
        <Stack>
          <FormControlLabel
            control={<Radio value="now" checked={selectedValue === "now"} onChange={handleRadioButtonChange}/>}
            label="Publish now"/>
          <Grid container>
            <Grid item sm={3}>
              <FormControlLabel
                control={<Radio value="later" checked={selectedValue === "later"} onChange={handleRadioButtonChange}/>}
                label="Publish later"
              />
            </Grid>
            <Grid item sm={9}>
              <DateTimePicker
                onChange={setPublishTime}
                referenceDate={dayjs().set("hours", 16)}
                disablePast
                disabled={selectedValue !== "later"}
                slotProps={{textField: {fullWidth: true, size: "small"}}}
                label="Publish time"
              />
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={selectedValue === "later" && publishTime === null}
          onClick={handlePublish}
        >Publish</Button>
        <Button
          variant="outlined"
          onClick={handleCancel}
        >Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}