import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import React from "react";

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';

import { DatePicker } from "@mui/x-date-pickers";
import { WebsiteSelector } from "./WebsiteSelector";
import { useArticleStore } from "../../writing-task/dao/ArticleStoreProvider";
import Grid from "@mui/material/Unstable_Grid2";

export function NewArticleDialog(props: {
  isOpen: boolean, 
  setIsOpen: (b: boolean) => void 
}) {

  const articleStore = useArticleStore();

  const websiteNameRef        = React.useRef<HTMLInputElement>(null);
  const mainCategoryRef       = React.useRef<HTMLInputElement>(null);
  const categoryKeywordRef    = React.useRef<HTMLInputElement>(null);
  const pageKeywordRef        = React.useRef<HTMLInputElement>(null);
  const keywordResearchUrlRef = React.useRef<HTMLInputElement>(null);
  const contentUrlRef         = React.useRef<HTMLInputElement>(null);
  const writerNameRef         = React.useRef<HTMLInputElement>(null);
  const publishDateRef        = React.useRef<HTMLInputElement>(null);
  const commentsRef           = React.useRef<HTMLInputElement>(null);
  const writingCostInUsdRef   = React.useRef<HTMLInputElement>(null);
  const publishedUrlRef       = React.useRef<HTMLInputElement>(null);

  return (
    <Dialog fullWidth maxWidth="md" open={props.isOpen} onClose={(event, reason) => {if(reason === 'backdropClick') {} else {props.setIsOpen(false)}}}>
      <DialogTitle>Create Article</DialogTitle>

      <IconButton onClick={() => props.setIsOpen(false)} color="info" sx={{ position: 'absolute', right: 10, top: 10}}>
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid xs={6}><WebsiteSelector                                   inputRef={websiteNameRef} required fullWidth/></Grid>
          <Grid xs={6}><TextField label="Main Keyword"                    inputRef={mainCategoryRef} required fullWidth/></Grid>
          <Grid xs={6}><TextField label="Category Keyword (Optional)"     inputRef={categoryKeywordRef} fullWidth/></Grid>
          <Grid xs={6}><TextField label="Page Keyword"                    inputRef={pageKeywordRef} required fullWidth/></Grid>
          <Grid xs={6}><TextField label="Keyword Research URL (Optional)" inputRef={keywordResearchUrlRef} fullWidth/></Grid>
          <Grid xs={6}><TextField label="Content URL (Optional)"          inputRef={contentUrlRef} fullWidth/></Grid>
          <Grid xs={6}><TextField label="Writer (Optional)"               inputRef={writerNameRef} fullWidth/></Grid>
          <Grid xs={6}><DatePicker label="Publish Date"                   inputRef={publishDateRef} slotProps={{ textField: { fullWidth: true, required: true } }}/></Grid>
          <Grid xs={6}><TextField label="Price (Optional)"                inputRef={writingCostInUsdRef} fullWidth/></Grid>
          <Grid xs={6}><TextField label="Published URL (Optional)"        inputRef={publishedUrlRef} fullWidth/></Grid>
          <Grid xs={12}><TextField label="Comments (Optional)"            inputRef={commentsRef} fullWidth multiline rows={4}/></Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => {
          const websiteName            = websiteNameRef.current?.value;
          const mainKeyword            = mainCategoryRef.current?.value;
          const pageKeyword            = pageKeywordRef.current?.value;
          const plannedPublicationDate = publishDateRef.current?.value;
          const categoryKeyword        = categoryKeywordRef.current?.value;
          const writerName             = writerNameRef.current?.value;
          const keywordResearchUrl     = keywordResearchUrlRef.current?.value;
          const contentEditorUrl       = contentUrlRef.current?.value;
          const comments               = commentsRef.current?.value;
          const writingCostInUsd       = writingCostInUsdRef.current?.value;
          const publishedUrl           = publishedUrlRef.current?.value;

          if (
            websiteName            && websiteName.length > 0 && 
            mainKeyword            && mainKeyword.length > 0 && 
            pageKeyword            && pageKeyword.length > 0 &&
            plannedPublicationDate && plannedPublicationDate.length > 0 &&
            categoryKeyword    !== undefined &&
            writerName         !== undefined &&
            keywordResearchUrl !== undefined &&
            contentEditorUrl   !== undefined &&
            writingCostInUsd   !== undefined &&
            publishedUrl       !== undefined &&
            comments           !== undefined
          ) {

            articleStore.createArticle({
              websiteName,
              mainKeyword,
              pageKeyword,
              plannedPublicationDate,
              categoryKeyword,
              writerName,
              keywordResearchUrl,
              contentEditorUrl,
              writingCostInUsd,
              publishedUrl,
              comments,
            });
          }
        }} variant="contained" startIcon={<AddIcon/>}>Create</Button>
        <Button onClick={() => props.setIsOpen(false)} variant="outlined" startIcon={<ClearIcon/>}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}