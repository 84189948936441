import React, {useState} from "react";
import {Page} from "../../components/Page";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select, Skeleton,
  Snackbar,
  Stack,
  TextField
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import {postFetchSurferDocument, postTaskFetchGoogleDocument} from "../../apis/Apis";
import {useMutation} from "react-query";
import CircularProgress from '@mui/material/CircularProgress';
import CancelIcon from '@mui/icons-material/Cancel';
import MuiAlert, {AlertProps} from '@mui/material/Alert';
import {useAuth} from "../../providers/AuthProvider";
import {useRemoteJob} from "../../remote-jobs/RemoteJobProvider";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {useWebsiteStore} from "../../website/WebsiteStoreProvider";
import {useContentStore} from "../ContentStoreProvider";
import {AllContentsTable} from "../components/AllContentsTable";
import _ from "lodash";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MAX_RESULTS = 12;

export function AllContentsPage() {
  const { accessTokenPromise } = useAuth();
  const contentStore = useContentStore();

  const {watchTask} = useRemoteJob();
  const {websites} = useWebsiteStore();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState<{open: boolean, text: string, severity: 'success' | 'error'}>({open: false, text: '', severity: 'success'});
  const [url, setUrl] = useState("");
  const [website, setWebsite] = useState("");
  const [maxResults, setMaxResults] = useState(MAX_RESULTS);

  const postFetchTask = useMutation((form: { googleDocUrl: string, websiteName: string}) => {
    setIsLoading(true);
    return accessTokenPromise
      .then(accessToken => {
        return postTaskFetchGoogleDocument(accessToken, form.googleDocUrl, form.websiteName)
      })
      .then(response => {
        watchTask(response.data);
        setNotification({open: true, text: 'Document is being downloaded', severity: 'success'});
        })
      .catch(error => {
        setNotification({open: true, text: `Error: ${error.message}`, severity: 'error'});
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  const postFetchSurferTask = useMutation((form: { websiteName: string, surferFiles: FileList}) => {
    setIsLoading(true);
    return accessTokenPromise
      .then(accessToken => {
        return postFetchSurferDocument(accessToken, form.websiteName, form.surferFiles)
      })
      .then(response => {
        watchTask(response.data);
        setNotification({open: true, text: 'Document is being downloaded', severity: 'success'});
        })
      .catch(error => {
        setNotification({open: true, text: `Error: ${error.message}`, severity: 'error'});
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  const [websiteNameFilter, setWebsiteNameFilter]     = React.useState<string | undefined>(undefined);
  const [documentTitleFilter, setDocumentTitleFilter] = React.useState<string | undefined>(undefined);
  const [statusFilter, setStatusFilter]               = React.useState<string | undefined>(undefined);
  const [mdStringFilter, setMdStringFilter]           = React.useState<string | undefined>(undefined);
  const [featuredFilter, setFeaturedFilter]           = React.useState<boolean | undefined>(undefined);
  const [sponsoredFilter, setSponsoredFilter]         = React.useState<boolean | undefined>(undefined);
  const [aiArticleFilter, setAiArticleFilter]         = React.useState<boolean | undefined>(undefined);

  const datePicker  = React.useRef<HTMLInputElement>(null);
  const surferFiles = React.useRef<HTMLInputElement>(null);

  const contentsById = contentStore.isInitialized ? contentStore.contentsById : []

  const contentsToDisplay = _(Object.values(contentsById))
    .filter(content => {
      return (
        !content.isArchived &&
        (featuredFilter === undefined || content.isFeatured === featuredFilter) &&
        (sponsoredFilter === undefined || content.isSponsored === sponsoredFilter) &&
        (aiArticleFilter === undefined || (!aiArticleFilter ? content.isAiArticle === undefined : content.isAiArticle === aiArticleFilter)) &&
        (websiteNameFilter === undefined || websiteNameFilter.length === 0 || content.websiteName.toLowerCase().indexOf(websiteNameFilter?.toLowerCase()) !== -1) &&
        (statusFilter === undefined || (statusFilter === "draft" ? content.publishTime === undefined : statusFilter === "published" ? content.publishTime !== undefined && content.publishTime <= new Date().getTime() : statusFilter === "scheduled" ? content.publishTime !== undefined && content.publishTime > new Date().getTime() : false)) &&
        (documentTitleFilter === undefined || documentTitleFilter.length === 0 || content.documentTitle.toLowerCase().indexOf(documentTitleFilter?.toLowerCase()) !== -1) &&
        (mdStringFilter === undefined || mdStringFilter.length === 0 || content.markdownString.toLowerCase().indexOf(mdStringFilter?.toLowerCase()) !== -1)
      );
    })
    .sortBy(content => [-content.lastUpdated, content.isFeatured])
    .take(maxResults)
    .value();

  return (
    <>
      <Page title={'Posts'}>
        <Stack spacing={2}>
          <Stack pt={'1em'} direction={'row'} spacing={1} display={'block'}>
            <Button variant="contained" startIcon={<DownloadIcon/>} onClick={() => setIsOpen(true)}>From GoogleDoc</Button>
            <Button variant="contained" startIcon={<DownloadIcon/>} onClick={() => setIsOpen2(true)}>From Surfer</Button>
          </Stack>
          <Stack pt={'1em'} direction={'row'} spacing={1} display={'block'}>
            <TextField sx={{width: "10em"}} size="small" label="Website" placeholder="Show All" onChange={event => { event.target.value === undefined || event.target.value.length === 0 ? setWebsiteNameFilter(undefined) : setWebsiteNameFilter(event.target.value) }}/>
            <TextField sx={{width: "10em"}} size="small" label="Document Title" placeholder="Show All" onChange={event => { event.target.value === undefined || event.target.value.length === 0 ? setDocumentTitleFilter(undefined) : setDocumentTitleFilter(event.target.value) }}/>
            <TextField sx={{width: "10em"}} size="small" label="Document Text" placeholder="Show All" onChange={event => { event.target.value === undefined || event.target.value.length === 0 ? setMdStringFilter(undefined) : setMdStringFilter(event.target.value) }}/>
            <FormControl>
              <InputLabel>Status</InputLabel>
              <Select
                size="small"
                sx={{width: "10em"}}
                label="Status"
                defaultValue="all"
                onChange={event => {
                  event.target.value === undefined || event.target.value === "all" ? setStatusFilter(undefined) : setStatusFilter(event.target.value)
                }}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="draft">Draft</MenuItem>
                <MenuItem value="scheduled">Scheduled</MenuItem>
                <MenuItem value="published">Published</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>Only sponsored</InputLabel>
              <Select
                size="small"
                sx={{width: "10em"}}
                label="Only sponsored"
                defaultValue="all"
                onChange={event => { event.target.value === undefined || event.target.value === "all" ? setSponsoredFilter(undefined) : event.target.value === "yes" ? setSponsoredFilter(true) : setSponsoredFilter(false)}}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>Only featured</InputLabel>
              <Select
                size="small"
                sx={{width: "10em"}}
                label="Only featured"
                defaultValue="all"
                onChange={event => { event.target.value === undefined || event.target.value === "all" ? setFeaturedFilter(undefined) : event.target.value === "yes" ? setFeaturedFilter(true) : setFeaturedFilter(false)}}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>Only ai</InputLabel>
              <Select
                size="small"
                sx={{width: "10em"}}
                label="Only ai"
                defaultValue="all"
                onChange={event => { event.target.value === undefined || event.target.value === "all" ? setAiArticleFilter(undefined) : event.target.value === "yes" ? setAiArticleFilter(true) : setAiArticleFilter(false)}}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
          </Stack>

          { contentStore.isInitialized
            ? <Paper elevation={2} sx={{p: "24px 16px"}}>
                <Stack spacing={2}>
                  <AllContentsTable contentsToDisplay={contentsToDisplay}/>
                  { Object.values(contentsById).length > maxResults
                    ? <Button variant="outlined" onClick={() => setMaxResults(max => max + MAX_RESULTS)}>Show more</Button>
                    : <></>
                  }
                </Stack>
              </Paper>
            : <Skeleton variant="rounded" height="30em"/>
          }
        </Stack>
      </Page>

      <Dialog fullWidth maxWidth="lg" open={isOpen2} onClose={(event, reason) => {if(reason === 'backdropClick') {} else {setIsOpen2(false)}}}>
      <DialogTitle>
        Get Surfer Doc
      </DialogTitle>

        <DialogContent>
            
            <Stack direction='row' spacing={2} sx={{mt: '1em'}}>

            <FormControl sx={{width:'12em', mt: '1em'}}>
              <InputLabel id="demo-simple-select-label">Website *</InputLabel>
              <Select
                value={website}
                label="Website *"
                onChange={(event) => setWebsite(event.target.value)}
              >
                {websites.map(website => <MenuItem key={website.websiteName} value={website.websiteName}>{website.websiteLabel}</MenuItem>)}
              </Select>
            </FormControl>
        
            <TextField
              id="surferFiles"
              label="Surfer File"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                multiple: true
              }}
              type="file"
              required
              fullWidth
              inputRef={surferFiles}
            />
            </Stack>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={notification.open} autoHideDuration={3000} onClose={() => setNotification({...notification, open: false})}>
          <Alert severity={notification.severity}>{notification.text}</Alert>
        </Snackbar>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            if (
              website             &&
              surferFiles.current?.files
            ) {
            postFetchSurferTask.mutate({websiteName: website, surferFiles: surferFiles.current.files });
            }
          }} variant="contained" startIcon={isLoading ? <CircularProgress color="info" size={20}/> : <DownloadIcon/>}>Get</Button>
          <Button onClick={() => setIsOpen2(false)} startIcon={<CancelIcon/>}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="lg" open={isOpen} onClose={(event, reason) => {if(reason === 'backdropClick') {} else {setIsOpen(false)}}}>
      <DialogTitle>
        Get Google Doc
      </DialogTitle>

        <DialogContent>
            
            <FormControl sx={{width:'12em', mt: '1em'}}>
              <InputLabel id="demo-simple-select-label">Website</InputLabel>
              <Select
                value={website}
                label="Website"
                onChange={(event) => setWebsite(event.target.value)}
              >
                {websites.map(website => <MenuItem key={website.websiteName} value={website.websiteName}>{website.websiteLabel}</MenuItem>)}
              </Select>
            </FormControl>

            <TextField
             sx={{mt:"1em"}}
              label="One or more Google Doc Urls"
              multiline
              rows={8}
              maxRows={8}
              fullWidth
              value={url}
              onChange={e => setUrl(e.target.value)}
            />
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={notification.open} autoHideDuration={3000} onClose={() => setNotification({...notification, open: false})}>
          <Alert severity={notification.severity}>{notification.text}</Alert>
        </Snackbar>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => postFetchTask.mutate({googleDocUrl: url, websiteName: website}) } variant="contained" startIcon={isLoading ? <CircularProgress color="info" size={20}/> : <DownloadIcon/>}>Get</Button>
          <Button onClick={() => setIsOpen(false)} startIcon={<CancelIcon/>}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
