import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import React from "react";
import {useContentStore} from "../../../ContentStoreProvider";
import Models from "../../../api/Models";
import {useSnackbar} from "../../../../common/SnackbarProvider";
import Typography from "@mui/material/Typography";

export function UnpublishDialog(props: {
  contentId: string,
  isOpen: boolean,
  setIsOpen: (_: boolean) => void,
}) {
  const {unpublishContent} = useContentStore();
  const snackbar = useSnackbar();

  const handleUnpublish = () => {

    const command: Models.UnpublishContentCommand = {
      contentId: props.contentId,
    };

    unpublishContent(command)
      .then(_ => {
        snackbar.showSuccess("Content is successfully unpublished");
        props.setIsOpen(false);
      })
      .catch(error => {
        snackbar.showError(`Error: ${error.message}`);
      });
  };

  const handleCancel = () => {
    props.setIsOpen(false);
  }

  return (
    <Dialog fullWidth open={props.isOpen} maxWidth="sm" onClose={handleCancel}>
      <DialogTitle>Unpublish Content</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">Do you really want to unpublish this article?</Typography>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            onClick={handleUnpublish}
          >Unpublish</Button>
          <Button
            variant="outlined"
            onClick={handleCancel}
          >Cancel</Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}