import React from "react";
import {DBSchema, IDBPDatabase, openDB} from "idb";
import Models from "../content/api/Models";
import ContentState = Models.ContentState;
import {Article} from "../scenes/articles/ArticleModels";

export function useDb() {
  return React.useContext(DbContext);
}

const DATABASE_NAME = "BECCHI_DB";
const DATABASE_VERSION = 1;

export interface BecchiDB extends DBSchema {
  "contents": {
    key   : string,
    value : ContentState,
  },
  "writing-tasks": {
    key   : string,
    value : Article,
  },
  "update-timestamps": {
    key   : string,
    value : number,
  },
}

export type DB = IDBPDatabase<BecchiDB>;

export function DbProvider({children}: { children: React.ReactNode }) {
  const [db, setDb] = React.useState<IDBPDatabase<BecchiDB>>();

  React.useEffect(() => {
    if (db === undefined) {
      openDatabase()
        .then(db => {
          console.debug(`Database connection opened to ${db.name}`);
          setDb(db);
        })
        .catch(error => {
          console.error("Couldn't open database", error);
        });
    } else {
      return () => {
        console.debug(`Database connection closed to ${db.name}`);
        db.close();
      }
    }
  }, [db, setDb]);

  return db === undefined
    ? <></>
    : <DbContext.Provider value={{db}}>
        {children}
      </DbContext.Provider>
    ;
}

function openDatabase() {
  return openDB<BecchiDB>(DATABASE_NAME, DATABASE_VERSION, {
    upgrade(db) {
      db.createObjectStore("contents", { keyPath: "id" })
      db.createObjectStore("writing-tasks", { keyPath: "taskId" })
      db.createObjectStore("update-timestamps")
    },
  })
}

const DbContext = React.createContext<DbContextType>(null!);

interface DbContextType {
  db: DB,
}