import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import React from "react";
import {useContentStore} from "../../../ContentStoreProvider";
import Models from "../../../api/Models";
import {useSnackbar} from "../../../../common/SnackbarProvider";
import Typography from "@mui/material/Typography";

export function ArchiveDialog(props: {
  contentId: string,
  isOpen: boolean,
  setIsOpen: (_: boolean) => void,
}) {
  const {archiveContent} = useContentStore();
  const snackbar = useSnackbar();

  const handleArchive = () => {

    const command: Models.ArchiveContentCommand = {
      contentId: props.contentId,
    };

    archiveContent(command)
      .then(_ => {
        snackbar.showSuccess("Content is successfully archived");
        props.setIsOpen(false);
      })
      .catch(error => {
        snackbar.showError(`Error: ${error.message}`);
      });
  };

  const handleCancel = () => {
    props.setIsOpen(false);
  }

  return (
    <Dialog fullWidth open={props.isOpen} maxWidth="sm" onClose={handleCancel}>
      <DialogTitle>Archive Content</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">Do you really want to archive this article?</Typography>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            onClick={handleArchive}
          >Archive</Button>
          <Button
            variant="outlined"
            onClick={handleCancel}
          >Cancel</Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}