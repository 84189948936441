import React from "react";
import {Page} from "../../components/Page";
import {Button, Checkbox, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack} from "@mui/material";
import { Dialog } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useMutation } from "react-query";
import { postBuildAndDeploy } from "../../apis/Apis";
import { useRemoteJob } from "../../remote-jobs/RemoteJobProvider";
import { useWebsiteStore } from "../../website/WebsiteStoreProvider";

export function AllBuildsPage() {

  const {websites} = useWebsiteStore();
  const {watchTask} = useRemoteJob();
  const [isOpen, setIsOpen] = React.useState(false);

  const websiteName = React.useRef<HTMLInputElement>(null);
  const shouldDeploy = React.useRef<HTMLInputElement>(null);

  const postBuildAndDeployTask = useMutation((form: { websiteName: string, shouldDeploy: boolean }) => {
    return postBuildAndDeploy(form.websiteName, form.shouldDeploy)
      .then(response => {
        watchTask(response.data);
      });
  });

  return (
    <>
      <Page title={'All Builds'}>
        <Grid container spacing={4}>
          <Grid item sm={12}>
            <Stack pt={'1em'} direction={'row'} spacing={1} display={'block'}>
              <Button variant="contained" startIcon={<PlayCircleIcon/>} onClick={() => setIsOpen(true)}>Deploy Website</Button>
            </Stack>
          </Grid>
        </Grid>
      </Page>
      
      <Dialog fullWidth maxWidth="lg" open={isOpen} onClose={(event, reason) => {if(reason === 'backdropClick') {} else {setIsOpen(false)}}}>
      <DialogTitle>
        Deploy Website
      </DialogTitle>

        <DialogContent>
            
            <FormControl sx={{width:'12em', mt: '1em'}}>
              <InputLabel>Website</InputLabel>
              <Select
                inputRef={websiteName}
                label="Website"
              >
              {websites.map(website => <MenuItem key={website.websiteName} value={website.websiteName}>{website.websiteLabel}</MenuItem>)}
              </Select>
            </FormControl>
            
            
            <FormControl sx={{width:'12em', mt: '1em'}}>
              <FormControlLabel control={<Checkbox defaultChecked inputRef={shouldDeploy}/>} label="Should Deploy" />
              
            </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            if (websiteName.current && shouldDeploy.current) {
              postBuildAndDeployTask.mutate({
                websiteName: websiteName.current.value,
                shouldDeploy: shouldDeploy.current.checked,
              }) 
            }
          }} variant="contained">Start</Button>
          <Button onClick={() => setIsOpen(false)} startIcon={<CancelIcon/>}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}