import {isEqual, isUndefined, omitBy} from "lodash";

/**
 * The normal `isEqual` function is not ignoring `undefined` values. This
 * version is ignoring undefined values.
 *
 * For example:
 *
 * ```
 * const x = { a: 1, b: undefined };
 * const y = { a: 1 };
 *
 * isEqual(x, y) === false
 * isEqualIgnoreUndefined(x, y) === true
 * ```
 */
export function isEqualIgnoreUndefined(a: any, b: any): boolean {
    const aWithoutUndefinedFields = omitBy(a, isUndefined);
    const bWithoutUndefinedFields = omitBy(b, isUndefined);
    
    return isEqual(aWithoutUndefinedFields, bWithoutUndefinedFields);
}
