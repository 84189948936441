import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import {SideBar} from "./SideBar";
import {Paper} from "@mui/material";
import React, {useEffect} from "react";
import Typography from "@mui/material/Typography";

export function Page(props: React.PropsWithChildren<{ title: string }>) {
  useEffect(() => {
    document.title = `${props.title} - Becchi`;
  }, [props.title]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline/>
      <SideBar/>
      <Paper square sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        minHeight: '100vh',
        width: '100%',
        p: '0 1em',
      }} elevation={1}>
        <Box component="main" sx={{flexGrow: 1, p: 3}}>
          <Typography variant={"h1"} gutterBottom>{props.title}</Typography>
          {props.children}
        </Box>
      </Paper>
    </Box>
  );
}