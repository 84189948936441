import {Navigate, Route, Routes} from "react-router-dom";
import {AllContentsPage} from "./content/pages/AllContentsPage";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AllImagesPage } from "./scenes/images/AllImagesPage";
import { AllBuildsPage } from "./scenes/builds/AllBuildsPage";
import { AllArticlesPage } from "./scenes/articles/AllArticlesPage";
import { LoginPage } from "./scenes/LoginPage";
import { AuthProvider } from "./providers/AuthProvider";
import { RequireAuth } from "./auth/RequireAuth";
import { RemoteJobProvider } from "./remote-jobs/RemoteJobProvider";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ArticleStoreProvider } from "./writing-task/dao/ArticleStoreProvider";
import { WebsiteStoreProvider } from "./website/WebsiteStoreProvider";
import { ContentStoreProvider } from "./content/ContentStoreProvider";
import { ContentEditPage } from "./content/pages/edit/ContentEditPage";
import {SnackbarProvider} from "./common/SnackbarProvider";
import {DbProvider} from "./common/DbProvider";

function App() {

  const darkTheme = createTheme({
    palette: {
      mode: window.localStorage.getItem("preferredPalette") === "dark" ? "dark" : "light",
    },
    typography: {
      h1: {
        fontSize: "26px",
        fontWeight: 600,
      },
      h2: {
        fontSize: "18px",
        fontWeight: "700",
        marginBottom: "12px",
      },
      h3: {
        fontSize: "15px",
        fontWeight: "700",
        marginBottom: "6px",
      },
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
    <LocalizationProvider dateFormats={{keyboardDate: "YYYY-MM-DD", fullDate: "YYYY-MM-DD" }} dateAdapter={AdapterDayjs}>
      <SnackbarProvider>
      <DbProvider>
      <AuthProvider>
      <RemoteJobProvider>
      <ContentStoreProvider>
      <ArticleStoreProvider>
      <WebsiteStoreProvider>
        <CssBaseline/>        
        <Routes>
          {/* Public route */}
          <Route path="login" element={<LoginPage/>}/>

          {/* Protected routes */}
          <Route path="articles/all" element={<RequireAuth><AllArticlesPage/></RequireAuth>}/>
          <Route path="images" element={<RequireAuth><AllImagesPage/></RequireAuth>}/>
          <Route path="builds" element={<RequireAuth><AllBuildsPage/></RequireAuth>}/>

          {/* Content routes */}
          <Route path="/contents/lists/all"       element={<RequireAuth><AllContentsPage/></RequireAuth>}/>
          <Route path="/contents/:contentId/edit" element={<RequireAuth><ContentEditPage/></RequireAuth>}/>

          {/* default route */}
          <Route path="*" element={<Navigate to="/contents/lists/all" replace/>}/>
        </Routes>
      </WebsiteStoreProvider>
      </ArticleStoreProvider>
      </ContentStoreProvider>
      </RemoteJobProvider>
      </AuthProvider>
      </DbProvider>
      </SnackbarProvider>
    </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
