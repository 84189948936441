import axios from "axios";
import { Website } from "./WebsiteModels";
import { HOST } from "../Constants";

export function listAllWebsitesApi(accessToken: string | undefined) {
  
  return axios.get<ReadonlyArray<Website>>(`${HOST}/websites/list`,
    { 
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
    }
  );
}
