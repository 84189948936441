import axios from "axios";
import { RemoteJob } from "./RemoteJobModels";
import { HOST } from "../Constants";

export function getRemoteJobDetails(accessToken: string | undefined, jobId: string) {
  return axios.get<RemoteJob>(`${HOST}/jobs/${jobId}`,
    { headers: {
      'Authorization': `Bearer ${accessToken}`,
    }});
}

export function getAllRemoteJobs(accessToken: string | undefined) {
  return axios.get<ReadonlyArray<RemoteJob>>(`${HOST}/jobs`,
    { headers: {
      'Authorization': `Bearer ${accessToken}`,
    }});
}