import { Alert, Button, Container, Stack, TextField } from "@mui/material";
import { useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import LoginIcon from '@mui/icons-material/Login';
import { useState } from "react";

// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx
export function LoginPage() {

  const navigate = useNavigate();
  const location = useLocation();
  const auth     = useAuth();

  const [errorMessage, setErrorMessage] = useState<string>();

  const from = location.state?.from?.pathname || "/";

  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
 
  const loginHandler = (event: React.FormEvent) => {
    event.preventDefault();

    const username = usernameRef.current?.value;
    const password = passwordRef.current?.value;
    
    if (username && password) {
      const onOk = () => { 
        setErrorMessage(undefined);
        navigate(from, { replace: true });
      };
      const onKo = () => { 
        setErrorMessage("Invalid username or password!");
      };
      auth.login(username, password, onOk, onKo);
    } else {
      setErrorMessage("Missing username or password!");
    }
  };

  return (
    auth.tokens 
      ? <Navigate to={from} replace />
      : <Container sx={{mt: '2em'}} maxWidth='xs'>
          <form onSubmit={loginHandler}>
            <Stack direction='column' spacing={2}>
              <TextField
                label='Username'
                placeholder='Username'
                size='small'
                type='text'
                inputRef={usernameRef}
                fullWidth
              />
              <TextField
                label='Password'
                placeholder='Password'
                size='small'
                type='password'
                inputRef={passwordRef}
                fullWidth
              />
              <Button type='submit' startIcon={<LoginIcon/>} variant='contained' color='success' fullWidth>Login</Button>
              { errorMessage 
                ? <Alert severity="error">{errorMessage}</Alert>
                : <></>
              }
            </Stack>
          </form>
        </Container>
  )
}