import axios from "axios";
import { HOST } from "../../Constants";

export function postLogin(username: string, password: string) {
  return axios.post<AuthTokens>(`${HOST}/login`,
    { username, password },
    { headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }});
}

export function postRefreshToken(refreshToken: string) {
  return axios.post<AuthTokens>(`${HOST}/refresh`,
    { refreshToken },
    { headers: { 
        'Content-Type': 'application/x-www-form-urlencoded',
      }, 
      validateStatus: () => true,
    }
  );
}

export function postLogout(accessToken: string | undefined) {
  return axios.post<void>(`${HOST}/logout`,
    {},
    { headers: { 
      'Authorization': `Bearer ${accessToken}`,
    }});
}

type AuthTokens = {
  accessToken: string,
  refreshToken: string,
  accessTokenValidUntil: number,
  refreshTokenValidUntil: number,
}