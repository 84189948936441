import React, {useRef, useState} from "react";
import {Page} from "../../components/Page";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, TextField} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import {useMutation} from "react-query";
import {deleteImage, finalizeImage, longTyetye, postNewImage} from "../../apis/Apis";
import {useAuth} from "../../providers/AuthProvider";
import {useRemoteJob} from "../../remote-jobs/RemoteJobProvider";

export function AllImagesPage() {

  const remoteJob = useRemoteJob();
  const [isOpen, setIsOpen] = useState(false);
  
  const imageAlias         = useRef<HTMLInputElement>(null);
  const imageAlt           = useRef<HTMLInputElement>(null);
  const imageCopyrightHtml = useRef<HTMLInputElement>(null);
  const imageFile          = useRef<HTMLInputElement>(null);

  const postFetchTask = useMutation((form: { imageAlt: string, imageCopyrightHtml: string, imageFile: File, imageAlias?: string}) => {
    return postNewImage(form.imageAlt, form.imageCopyrightHtml, form.imageFile, form.imageAlias)
      .then(response => {
        if(response.status === 200) {
          
        } else {
          
        }
      });
  });

  const { accessTokenPromise } = useAuth();
  const deleteImageTask = (imageId: string) => {
    
    return accessTokenPromise
      .then(accessToken =>
        deleteImage(accessToken, imageId)
      )
      .then(response => {
        if(response.status === 202) {
          remoteJob.watchTask(response.data);
        } else {
          // fixme explode!
        }
      });
  };
  
  const longTyetyeTask = () => {
    
    return accessTokenPromise
      .then(accessToken =>
        longTyetye(accessToken)
      )
      .then(response => {
        if(response.status === 202) {
          remoteJob.watchTask(response.data);
        } else {
          // fixme explode!
        }
      });
  };

  const finalizeImageTask = (imageId: string) => {
    
    return accessTokenPromise
      .then(accessToken =>
        finalizeImage(accessToken, imageId)
      )
      .then(response => {
        if(response.status === 200) {
          
        } else {
          
        }
      });
  };
  
  return (
    <>
      <Page title={'All Images'}>
        <Grid container spacing={4}>
          <Grid item sm={12}>
            <Stack pt={'1em'} direction={'row'} spacing={1} display={'block'}>
              <Button variant="contained" startIcon={<AddPhotoAlternateIcon/>} onClick={() => setIsOpen(true)}>Add Image</Button>
              <Button variant="contained" startIcon={<AddPhotoAlternateIcon/>} onClick={() => {
                deleteImageTask('numkl');
              }}>Delete image</Button> 
              <Button variant="contained" startIcon={<AddPhotoAlternateIcon/>} onClick={() => {
                finalizeImageTask('5p511');
                finalizeImageTask('pu984');
                finalizeImageTask('v0l3k');
              }}>Finalize image</Button>
              <Button variant="contained" startIcon={<AddPhotoAlternateIcon/>} onClick={() => longTyetyeTask()}>Long tyetye</Button>
            </Stack>
          </Grid>
        </Grid>
      </Page>

      
      <Dialog fullWidth maxWidth="lg" open={isOpen} onClose={(event, reason) => {if(reason === 'backdropClick') {} else {setIsOpen(false)}}}>
      <DialogTitle>
        Add Image
      </DialogTitle>

        <DialogContent>

            <TextField
              sx={{mt:"1em"}}
              id="imageAlias"
              label="Image Alias"
              fullWidth
              inputRef={imageAlias}
            />

            <TextField
              sx={{mt:"1em"}}
              id="imageAlt"
              label="Image Alt"
              required
              fullWidth
              inputRef={imageAlt}
            />

            <TextField
              sx={{mt:"1em"}}
              id="imageCopyrightHtml"
              label="Image Copyright Html"
              required
              fullWidth
              inputRef={imageCopyrightHtml}
            />

            <TextField
              sx={{mt:"1em"}}
              id="imageCopyrightHtml"
              label="Image File"
              InputLabelProps={{
                shrink: true,
              }}
              type="file"
              required
              fullWidth
              inputRef={imageFile}
            />
            
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            if (
              imageAlt.current           &&
              imageCopyrightHtml.current && 
              imageFile.current          &&
              imageFile.current.files
            ) {
              const aliasToSend = imageAlias.current?.value?.trim();
              postFetchTask.mutate({
                imageAlt:           imageAlt.current.value,
                imageCopyrightHtml: imageCopyrightHtml.current.value, 
                imageFile:          imageFile.current.files[0], 
                imageAlias:         (aliasToSend && aliasToSend.length !== 0) ? aliasToSend : undefined,
              }) 
            }
          }} variant="contained">Create</Button>
          <Button onClick={() => setIsOpen(false)} startIcon={<CancelIcon/>}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}