import React from "react";
import { Website } from "./WebsiteModels";
import { listAllWebsitesApi } from "./WebsiteApi";
import { useAuth } from "../providers/AuthProvider";

const WebsiteStoreContext = React.createContext<WebsiteStoreContextType>(null!);

export function WebsiteStoreProvider({ children }: { children: React.ReactNode }) {
    const auth = useAuth();

    const [websites, setWebsites] = React.useState<ReadonlyArray<Website>>();

    React.useEffect(() => {
        if (websites === undefined && auth.tokens) {
            initializeWebsiteStore(auth.accessTokenPromise, setWebsites);
            return () => {};
        }
    }, [websites, auth.tokens, auth.accessTokenPromise, setWebsites]);


    const contextValue = {
        websites: websites || [],
    };

    return <WebsiteStoreContext.Provider value={contextValue}>{children}</WebsiteStoreContext.Provider>;
}

export function useWebsiteStore() {
    return React.useContext(WebsiteStoreContext);
}

interface WebsiteStoreContextType {
    websites: ReadonlyArray<Website>,
}

function initializeWebsiteStore(
    accessTokenPromise: Promise<string | undefined>, 
    setWebsites: (websites: ReadonlyArray<Website>) => void,
) {
    accessTokenPromise
        .then(accessToken => {
            return listAllWebsitesApi(accessToken);
        })
        .then(response => {
            if (response.status === 200) {
                const websites = response.data;
                setWebsites(websites);
            } else {
                // ??? fixme
            }
        });
}
