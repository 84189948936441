import React from "react"
import Apis from "./api/Apis"
import Models from "./api/Models"
import {Dictionary, keyBy} from "lodash"
import {useAuth} from "../providers/AuthProvider"
import {ContentDao, useContentDao} from "./dao/ContentDao"
import {UpdateTimestampDao, useUpdateTimestampDao} from "./dao/UpdateTimestampDao";

const ContentStoreContext = React.createContext<ContentStoreContextType>(null!)
const CHECK_INTERVAL_IN_MS = 60 * 1000

type State = {
  contentsById: Dictionary<Models.ContentState>,
  snapshotTime: number,
}

export function ContentStoreProvider({children}: { children: React.ReactNode }) {
  const auth               = useAuth()
  const contentDao         = useContentDao()
  const updateTimestampDao = useUpdateTimestampDao()
  const [state, setState]  = React.useState<State>()

  React.useEffect(() => {
    if (auth.tokens) {
      if (state === undefined) {
        initializeState(auth.accessTokenPromise, contentDao, updateTimestampDao, setState)
      } else {
        const timeout = setTimeout(() => {
          updateState(auth.accessTokenPromise, contentDao, updateTimestampDao, state, setState)
        }, CHECK_INTERVAL_IN_MS)

        return () => {
          clearTimeout(timeout)
        }
      }
    }
  }, [auth.tokens, auth.accessTokenPromise, state, setState, contentDao, updateTimestampDao])

  const updateLocalState = async (updatedContent: Models.ContentState) => {
    const newContentsById = { ...state?.contentsById }
    newContentsById[updatedContent.id] = updatedContent

    // we don't set snapshot time, as it's possible some other thread
    // modified something between
    setState({
      ...state!,
      contentsById: newContentsById,
    })
  }

  const updateContent = async (cmd: Models.UpdateContentCommand) => {
    const accessToken    = await auth.accessTokenPromise
    const updatedContent = await Apis.updateContent(accessToken, cmd)

    await updateLocalState(updatedContent)
  }

  const publishContent = async (cmd: Models.PublishContentCommand) => {
    const accessToken    = await auth.accessTokenPromise
    const updatedContent = await Apis.publishContent(accessToken, cmd)

    await updateLocalState(updatedContent)
  }

  const unpublishContent = async (cmd: Models.UnpublishContentCommand) => {
    const accessToken    = await auth.accessTokenPromise
    const updatedContent = await Apis.unpublishContent(accessToken, cmd)

    await updateLocalState(updatedContent)
  }

  const archiveContent = async (cmd: Models.ArchiveContentCommand) => {
    const accessToken    = await auth.accessTokenPromise
    const updatedContent = await Apis.archiveContent(accessToken, cmd)

    await updateLocalState(updatedContent)
  }

  const unarchiveContent = async (cmd: Models.UnarchiveContentCommand) => {
    const accessToken    = await auth.accessTokenPromise
    const updatedContent = await Apis.unarchiveContent(accessToken, cmd)

    await updateLocalState(updatedContent)
  }

  const createAiDescription = async (cmd: Models.CreateAiDescriptionCommand) => {
    const accessToken    = await auth.accessTokenPromise
    await Apis.createAiDescription(accessToken, cmd)
  }

  const contextValue: ContentStoreContextType = state === undefined
    ? {
      isInitialized: false,
      updateContent,
      publishContent,
      unpublishContent,
      createAiDescription,
      archiveContent,
      unarchiveContent,
    }
    : {
      isInitialized: true,
      contentsById: state.contentsById,
      updateContent,
      publishContent,
      unpublishContent,
      createAiDescription,
      archiveContent,
      unarchiveContent,
    }

  return (
    <ContentStoreContext.Provider value={contextValue}>
      {children}
    </ContentStoreContext.Provider>
  )
}

export function useContentStore() {
  return React.useContext(ContentStoreContext)
}

type ContentStoreContextType = NotInitializedContentStoreContext | InitializedContentStoreContext

type NotInitializedContentStoreContext = {
  isInitialized:       false,
  updateContent:       (_: Models.UpdateContentCommand) => Promise<void>,
  publishContent:      (_: Models.PublishContentCommand) => Promise<void>,
  unpublishContent:    (_: Models.UnpublishContentCommand) => Promise<void>,
  createAiDescription: (_: Models.CreateAiDescriptionCommand) => Promise<void>,
  archiveContent:      (_: Models.ArchiveContentCommand) => Promise<void>,
  unarchiveContent:    (_: Models.UnarchiveContentCommand) => Promise<void>,
}

export interface InitializedContentStoreContext {
  isInitialized:       true,
  contentsById:        Dictionary<Models.ContentState>,
  updateContent:       (_: Models.UpdateContentCommand) => Promise<void>,
  publishContent:      (_: Models.PublishContentCommand) => Promise<void>,
  unpublishContent:    (_: Models.UnpublishContentCommand) => Promise<void>,
  createAiDescription: (_: Models.CreateAiDescriptionCommand) => Promise<void>,
  archiveContent:      (_: Models.ArchiveContentCommand) => Promise<void>,
  unarchiveContent:    (_: Models.UnarchiveContentCommand) => Promise<void>,
}

async function initializeState(
  accessTokenPromise: Promise<string | undefined>,
  contentDao:         ContentDao,
  updateTimestampDao: UpdateTimestampDao,
  setState:           (state: State) => void,
) {

  console.debug("Initializing content store")
  const contentsInDb = await contentDao.getAll()
  const lastUpdated  = await updateTimestampDao.get("contents")

  const accessToken = await accessTokenPromise
  const contentDataFromServer = await Apis.listContents(accessToken, lastUpdated)

  const contentsInDbById = keyBy(contentsInDb, content => content.id)
  const snapshotTime = contentDataFromServer.snapshotTime

  await updateTimestampDao.put("contents", snapshotTime)
  if (contentDataFromServer.contents.length > 0) {
    const contentsFromServerById = keyBy(contentDataFromServer.contents, content => content.id)
    await contentDao.putAll(contentDataFromServer.contents)

    const contentsById = { ...contentsInDbById, ...contentsFromServerById }

    setState({
      contentsById,
      snapshotTime,
    })
  } else {
    const contentsById = contentsInDbById

    setState({
      contentsById,
      snapshotTime,
    })
  }
}

async function updateState(
  accessTokenPromise: Promise<string | undefined>,
  contentDao:         ContentDao,
  updateTimestampDao: UpdateTimestampDao,
  state:              State,
  setState:           (state: State) => void,
) {
  const accessToken           = await accessTokenPromise
  const contentDataFromServer = await Apis.listContents(accessToken, state.snapshotTime)

  const snapshotTime = contentDataFromServer.snapshotTime

  await updateTimestampDao.put("contents", snapshotTime)

  if (contentDataFromServer.contents.length > 0) {
    const contentsFromServerById = keyBy(contentDataFromServer.contents, content => content.id)
    await contentDao.putAll(contentDataFromServer.contents)
    const contentsById = { ...(state.contentsById), ...contentsFromServerById }

    setState({
      contentsById,
      snapshotTime,
    })
  } else {

    setState({
      ...state,
      snapshotTime,
    })
  }
}
