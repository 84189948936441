import React, {useState} from "react";
import {Page} from "../../components/Page";
import {Link as RouterLink} from 'react-router-dom';
import {
  Button,
  Collapse,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  PaletteColor,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme, ToggleButton, ToggleButtonGroup,
  Typography,
  useTheme
} from "@mui/material";
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import {ArrowDropDownIcon, DatePicker} from "@mui/x-date-pickers";
import {KeyboardArrowDown, KeyboardArrowRight} from "@mui/icons-material";
import {Article} from "./ArticleModels";
import {useArticleStore} from "../../writing-task/dao/ArticleStoreProvider";
import {NewArticleDialog} from "./NewArticleDialog";
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from "@mui/material/Unstable_Grid2";
import {WebsiteSelector} from "./WebsiteSelector";
import dayjs from "dayjs";
import {DeleteArticleCommand, UpdateArticleCommand} from "./WritingTaskApi";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {SurferIcon} from "./SurferIcon";
import NotesIcon from '@mui/icons-material/Notes';
import {useSnackbar} from "../../common/SnackbarProvider";

export function AllArticlesPage() {

  const articleStore = useArticleStore();
  const [isOpen, setIsOpen] = useState(false);

  const [statusFilter,      setStatusFilter     ] = React.useState<"all" | "not-ready" | "ready">("not-ready");
  const [websiteNameFilter, setWebsiteNameFilter] = React.useState<string | undefined>(undefined);
  const [mainKeywordFilter, setMainKeywordFilter] = React.useState<string | undefined>(undefined);
  const [pageKeywordFilter, setPageKeywordFilter] = React.useState<string | undefined>(undefined);
  const [writerNameFilter,  setWriterNameFilter ] = React.useState<string | undefined>(undefined);

  const articlesInProgress = Object.values(articleStore.articlesById)
    .filter(article => {
      if (statusFilter === "all") {
        return true;
      } else if (statusFilter === "not-ready") {
        return article.status !== "Done" && article.status !== "Ready" && article.status !== "Recorded";
      } else { // "ready"
        return article.status === "Ready" || article.status === "Recorded";
      }
    })
    .filter(article => {
      return websiteNameFilter === undefined || websiteNameFilter === article.websiteName;
    })
    .filter(article => {
      return mainKeywordFilter === undefined || article.mainKeyword.toLowerCase().includes(mainKeywordFilter.toLowerCase());
    })
    .filter(article => {
      return pageKeywordFilter === undefined || article.pageKeyword.toLowerCase().includes(pageKeywordFilter.toLowerCase());
    })
    .filter(article => {
      return writerNameFilter === undefined || article.writerName.toLowerCase().includes(writerNameFilter.toLowerCase());
    })
    .sort((a, b) => ((a.plannedPublicationDate || '') + a.websiteName).localeCompare(((b.plannedPublicationDate || '') + b.websiteName)));

  return (
    <>
      <Page title={'Articles In Progress'}>
        <Grid container spacing={4}>
          <Grid sm={9}>
            <Stack>
              <Stack pt={'1em'} direction={'row'} spacing={2} display={'block'}>
                <Button fullWidth={false} variant="contained" startIcon={<AddIcon/>} onClick={() => setIsOpen(true)}>Create Article</Button>
              </Stack>
              <Stack pt={'1em'} direction={'row'} spacing={2} display={'block'}>
                <ToggleButtonGroup
                  color="primary"
                  size="small"
                  value={statusFilter}
                  onChange={(_, newValue) => setStatusFilter(newValue)}
                  exclusive
                  aria-label="Platform"
                >
                  <ToggleButton value="all">All</ToggleButton>
                  <ToggleButton value="not-ready">Not Ready</ToggleButton>
                  <ToggleButton value="ready">Ready</ToggleButton>
                </ToggleButtonGroup>
                <WebsiteSelector extraValues={["Show All"]} sx={{width: "10em"}} size="small" onChange={event => { event.target.value === "Show All" ? setWebsiteNameFilter(undefined) : setWebsiteNameFilter(event.target.value) }}/>
                <TextField sx={{width: "10em"}} size="small" label="Main Keyword" placeholder="Show All" onChange={event => { event.target.value === undefined || event.target.value.length === 0 ? setMainKeywordFilter(undefined) : setMainKeywordFilter(event.target.value) }}/>
                <TextField sx={{width: "10em"}} size="small" label="Page Keyword" placeholder="Show All" onChange={event => { event.target.value === undefined || event.target.value.length === 0 ? setPageKeywordFilter(undefined) : setPageKeywordFilter(event.target.value) }}/>
                <TextField sx={{width: "10em"}} size="small" label="Writer Name" placeholder="Show All" onChange={event => { event.target.value === undefined || event.target.value.length === 0 ? setWriterNameFilter(undefined) : setWriterNameFilter(event.target.value) }}/>
              </Stack>
            </Stack>
          </Grid>
          <Grid sm={12}>
            <ArticlesInProgressTable articlesInProgress={articlesInProgress}/>
          </Grid>
        </Grid>
      </Page>

      <NewArticleDialog isOpen={isOpen} setIsOpen={setIsOpen}/>
    </>
  )
}

function ArticlesInProgressTable(props: {
  articlesInProgress: ReadonlyArray<Article>,
}) {
  return (
      <Table size={'small'}>
      <TableHead>
        <TableRow>
          <TableCell align="left"   sx={{width: "5%"}}/>
          <TableCell align="left"   sx={{width: "12.5%"}}>Website</TableCell>
          <TableCell align="left"   sx={{width: "17.5%"}}>Main Keyword</TableCell>
          <TableCell align="left"   sx={{width: "18%"}}  >Page Keyword</TableCell>
          <TableCell align="center" sx={{width: "12%"}}  >Status</TableCell>
          <TableCell align="right"  sx={{width: "7.5%"}} >Writer</TableCell>
          <TableCell align="right"  sx={{width: "7.5%"}} >Price</TableCell>
          <TableCell align="right"  sx={{width: "7.5%"}} >Publication</TableCell>
          <TableCell align="right"  sx={{width: "12.5%"}}>Links</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.articlesInProgress.map(article => <ArticleInProgressRow key={article.taskId} article={article}/>)}
      </TableBody>
    </Table>
  );
}

function WritingStatusDropdown(props: {
  article: Article,
}) {
  const snackbar = useSnackbar();
  const articleStore = useArticleStore();
  const theme = useTheme();
  const colorForArticle = paletteForArticle(props.article, theme);

  return (

    <Select 
      id={`writing-task-row-status-selector-${props.article.taskId}`}
      value={props.article.status} 
      size="small" 
      onChange={e => {
        articleStore
          .updateArticle({ taskId: props.article.taskId, status: e.target.value })
          .catch(_ => snackbar.showError("Couldn't update article status"));
      }}
      sx={{
        textAlign: 'center',
        fontSize: "0.875rem",
        height: "2.1rem",
        backgroundColor: colorForArticle.main,
        color: colorForArticle.contrastText,
        '& MuiSelect-icon-root': { color: 'black'}
      }} 
      fullWidth
      IconComponent={(props) => <ArrowDropDownIcon {...props} sx={{ color: `${colorForArticle.contrastText}!important` }}/>}>
      <MenuItem value="To Do">To Do</MenuItem>
      <MenuItem value="Prepare Surfer">Prepare Surfer</MenuItem>
      <MenuItem value="Send link">Send link</MenuItem>
      <MenuItem value="In progress">In progress</MenuItem>
      <MenuItem value="Under review">Under review</MenuItem>
      <MenuItem value="Photo change">Photo change</MenuItem>
      <MenuItem value="Add link">Add link</MenuItem>
      <MenuItem value="Ready">Ready</MenuItem>
      <MenuItem value="Recorded">Recorded</MenuItem>
      <MenuItem value="Done">Done</MenuItem>
    </Select>
  );
}

function paletteForArticle(article: Article, theme: Theme) {
  switch(article.status) {
    case "To Do":          return WhitePaletteColor;
    case "Prepare Surfer": return theme.palette.primary;
    case "Send link":      return theme.palette.secondary;
    case "In progress":    return LightYellow;
    case "Under review":   return theme.palette.info;
    case "Photo change":   return theme.palette.warning;
    case "Add link":       return theme.palette.info;
    case "Ready":          return theme.palette.success;
    case "Recorded":       return theme.palette.success;
    case "Done":           return theme.palette.success;
  }
}

const WhitePaletteColor: PaletteColor = {
  light:        "#eeeeee",
  main:         "#eeeeee",
  dark:         "#eeeeee",
  contrastText: "#222222",
}

const LightYellow: PaletteColor = {
  light:        "#ffe69e",
  main:         "#ffe69e",
  dark:         "#ffe69e",
  contrastText: "#222222",
}

function ArticleInProgressRow(props: { article: Article }) {
  const snackbar = useSnackbar();
  const article = props.article;
  const articleStore = useArticleStore();
  const [isOpen, setIsOpen] = React.useState(false);

  const rowSx = (theme: Theme) => {
    return {
      borderLeftWidth: "4px",
      borderLeftStyle: "solid",
      borderLeftColor: isOpen ? theme.palette.primary.main : "transparent",
    };
  };

  const websiteNameRef        = React.useRef<HTMLInputElement>(null);
  const mainCategoryRef       = React.useRef<HTMLInputElement>(null);
  const categoryKeywordRef    = React.useRef<HTMLInputElement>(null);
  const pageKeywordRef        = React.useRef<HTMLInputElement>(null);
  const keywordResearchUrlRef = React.useRef<HTMLInputElement>(null);
  const contentUrlRef         = React.useRef<HTMLInputElement>(null);
  const writerNameRef         = React.useRef<HTMLInputElement>(null);
  const publishDateRef        = React.useRef<HTMLInputElement>(null);
  const commentsRef           = React.useRef<HTMLInputElement>(null);
  const writingCostInUsdRef   = React.useRef<HTMLInputElement>(null);
  const publishedUrlRef       = React.useRef<HTMLInputElement>(null);

  const onUpdate = () => {
    const updateCommand: UpdateArticleCommand =  {
      taskId:                 article.taskId,
      websiteName:            websiteNameRef.current?.value,
      mainKeyword:            mainCategoryRef.current?.value,
      categoryKeyword:        categoryKeywordRef.current?.value,
      pageKeyword:            pageKeywordRef.current?.value,
      status:                 article.status,
      writerName:             writerNameRef.current?.value,
      keywordResearchUrl:     keywordResearchUrlRef.current?.value,
      contentEditorUrl:       contentUrlRef.current?.value,
      plannedPublicationDate: publishDateRef.current?.value,
      comments:               commentsRef.current?.value,
      writingCostInUsd:       writingCostInUsdRef.current?.value,
      publishedUrl:           publishedUrlRef.current?.value,
    };

    articleStore
      .updateArticle(updateCommand)
      .then(_ => setIsOpen(false))
      .catch(_ => snackbar.showError("Couldn't update article"))
      ;
  }

  const onDelete = () => {
    const deleteCommand: DeleteArticleCommand = {
      taskId: article.taskId,
    };
    articleStore
      .deleteArticle(deleteCommand)
      .then(_ => {
        setIsOpen(false);
        setAnchorEl(undefined);
      })
      .catch(_ => snackbar.showError("Couldn't delete article"))
      ;
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(undefined);
  };

  return <>
    <TableRow id={`article-row-${article.taskId}`} sx={rowSx}>
      <TableCell align="left">
        <IconButton size="small" onClick={() => setIsOpen(!isOpen)}>{isOpen ? <KeyboardArrowDown/> : <KeyboardArrowRight/>}</IconButton>
      </TableCell>
      <TableCell align="left">{article.websiteName}</TableCell>
      <TableCell align="left">{article.mainKeyword}</TableCell>
      <TableCell align="left">{article.pageKeyword}</TableCell>
      <TableCell align="left"><WritingStatusDropdown article={article}/></TableCell>
      <TableCell align="right">{article.writerName || '-'}</TableCell>
      <TableCell align="right">{article.writingCostInUsd || '-'}</TableCell>
      <TableCell align="right">{article.plannedPublicationDate || '-'}</TableCell>
      <TableCell align="right">
        <Stack
          direction="row-reverse"
          spacing={0}
        >
          { article.contentEditorUrl ? <IconButton size="small" title="Go to Surfer" component={RouterLink} to={article.contentEditorUrl} target="_blank"><SurferIcon/></IconButton> : <></> }
          { article.publishedUrl ? <IconButton size="small" title="Go to Live URL" component={RouterLink} to={article.publishedUrl} target="_blank"><OpenInNewIcon/></IconButton> : <></> }
          { article.comments ? <IconButton size="small" disableRipple={true} title={article.comments}><NotesIcon/></IconButton> : <></> }
        </Stack>
      </TableCell>
    </TableRow>
    <TableRow sx={rowSx}>
      <TableCell sx={theme => ({ paddingBottom: 0, paddingTop: 0, borderBottomColor: isOpen ? theme.palette.divider : "transparent"})} colSpan={8}>
        <Collapse in={isOpen} timeout={0} unmountOnExit sx={{mb: '.75em', mt: '.75em'}}>
          <Grid container spacing={2}>
            <Grid lg={12}>
              <Typography variant="h6" gutterBottom component="div">Details</Typography>
            </Grid>
            <Grid xs={6}><WebsiteSelector defaultValue={article.websiteName}                      inputRef={websiteNameRef} required fullWidth/></Grid>
            <Grid xs={6}><TextField defaultValue={article.mainKeyword}        label="Main Keyword"                    inputRef={mainCategoryRef} required fullWidth/></Grid>
            <Grid xs={6}><TextField defaultValue={article.categoryKeyword}    label="Category Keyword (Optional)"     inputRef={categoryKeywordRef} fullWidth/></Grid>
            <Grid xs={6}><TextField defaultValue={article.pageKeyword}        label="Page Keyword"                    inputRef={pageKeywordRef} required fullWidth/></Grid>
            <Grid xs={6}><TextField defaultValue={article.keywordResearchUrl} label="Keyword Research URL (Optional)" inputRef={keywordResearchUrlRef} fullWidth/></Grid>
            <Grid xs={6}><TextField defaultValue={article.contentEditorUrl}   label="Content URL (Optional)"          inputRef={contentUrlRef} fullWidth/></Grid>
            <Grid xs={6}><TextField defaultValue={article.writerName}         label="Writer (Optional)"               inputRef={writerNameRef} fullWidth/></Grid>
            <Grid xs={6}><DatePicker label="Publish Date" defaultValue={dayjs(article.plannedPublicationDate)}        inputRef={publishDateRef} slotProps={{ textField: { fullWidth: true, required: true } }}/></Grid>
            <Grid xs={6}><TextField defaultValue={article.writingCostInUsd}   label="Price (Optional)"                inputRef={writingCostInUsdRef} fullWidth/></Grid>
            <Grid xs={6}><TextField defaultValue={article.publishedUrl}       label="Published URL (Optional)"        inputRef={publishedUrlRef} fullWidth/></Grid>
            <Grid xs={12}><TextField size="small" defaultValue={article.comments} label="Comments"                    inputRef={commentsRef} fullWidth multiline rows={4}/></Grid>
          
            <Grid xs={12} sx={{mt: ".25em", mb: ".75em"}}>
              <Stack spacing={2} direction="row">
                <Button variant="contained" color="primary" onClick={onUpdate} startIcon={<DoneIcon/>}>Update</Button>
                <Button variant="outlined" color="secondary" onClick={() => setIsOpen(false)} startIcon={<ClearIcon/>}>Cancel</Button>
                <div style={{marginLeft: 'auto'}}/>
                <Button variant="outlined" color="warning" onClick={handleClick} startIcon={<DeleteIcon/>}>Delete</Button>
                <Menu
                  id="delete-button-menu"
                  anchorEl={anchorEl}
                  open={open}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <ClearIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>No, don't delete</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={onDelete}>
                    <ListItemIcon>
                      <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Yes, delete it</ListItemText>
                  </MenuItem>
                </Menu>
              </Stack>
            </Grid>
          </Grid>
        </Collapse>
      </TableCell>
    </TableRow>
  </>;
}