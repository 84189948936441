import {useDb} from "../../common/DbProvider"
import React from "react"
import {Article} from "../../scenes/articles/ArticleModels";

export interface WritingTaskDao {
  putAll: (contents: ReadonlyArray<Article>) => Promise<void>
  getAll: () => Promise<ReadonlyArray<Article>>
}

export function useWritingTaskDao(): WritingTaskDao {
  const {db} = useDb()

  return React.useMemo(() => {
    console.debug("Creating WritingTaskDao")
    return ({
      putAll: (items: ReadonlyArray<Article>) => {
        const tx = db.transaction(["writing-tasks"], "readwrite")
        const writingTaskStore = tx.objectStore("writing-tasks")

        return (
          Promise
            .all(items.map(i => writingTaskStore.put(i)))
            .then(_ => {
            })
            .finally(() => {
              return tx.done
            })
        )
      },

      getAll: () => {
        const tx = db.transaction(["writing-tasks"], "readonly")
        const writingTaskStore = tx.objectStore("writing-tasks")

        return (
          writingTaskStore
            .getAll()
            .finally(() => {
              return tx.done
            })
        )
      }
    })
  }, [db])
}