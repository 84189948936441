import axios from "axios"
import { HOST } from "../../Constants"
import Models from "./Models"
import {extractData} from "../../support/ApiUtils"

namespace Apis {

  export async function listContents(
    accessToken    : string | undefined,
    snapshotTime   : number | undefined,
  ) {
    let allContents: Array<Models.ContentState> = [];
    let newSnapshotTime: number | undefined = undefined
    let afterContentId: string | undefined = undefined

    do {
      const response: Models.ContentListResponse = await listContentsBatch(accessToken, snapshotTime, afterContentId)
      allContents = allContents.concat(response.contents)
      afterContentId = response.lastContentId
      newSnapshotTime = response.snapshotTime
    } while(afterContentId !== undefined)

    return {
      contents: allContents,
      snapshotTime: newSnapshotTime,
    }
  }

  async function listContentsBatch(
    accessToken    : string | undefined,
    snapshotTime   : number | undefined,
    afterContentId : string | undefined,
  ) {

    const response = await axios.get<Models.ContentListResponse>(`${HOST}/contents/list`,
      {
        params: {
          updatedSince: snapshotTime,
          afterContentId: afterContentId,
        },
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      }
    )
    return extractData(response)
  }

  export async function updateContent(
    accessToken : string | undefined,
    command     : Models.UpdateContentCommand,
  ) {
    const response = await axios.post<Models.ContentState>(`${HOST}/contents/update`,
      command,
      { headers: { 'Authorization': `Bearer ${accessToken}` }},
    )

    return extractData(response)
  }

  export async function publishContent(
    accessToken : string | undefined,
    command     : Models.PublishContentCommand,
  ) {
    const response = await axios.post<Models.ContentState>(`${HOST}/contents/publish`,
      command,
      { headers: { 'Authorization': `Bearer ${accessToken}` }},
    )

    return extractData(response)
  }

  export async function unpublishContent(
    accessToken : string | undefined,
    command     : Models.UnpublishContentCommand,
  ) {
    const response = await axios.post<Models.ContentState>(`${HOST}/contents/unpublish`,
      command,
      { headers: { 'Authorization': `Bearer ${accessToken}` }},
    )

    return extractData(response)
  }

  export async function archiveContent(
    accessToken : string | undefined,
    command     : Models.ArchiveContentCommand,
  ) {
    const response = await axios.post<Models.ContentState>(`${HOST}/contents/archive`,
      command,
      { headers: { 'Authorization': `Bearer ${accessToken}` }},
    )

    return extractData(response)
  }

  export async function unarchiveContent(
    accessToken : string | undefined,
    command     : Models.UnarchiveContentCommand,
  ) {
    const response = await axios.post<Models.ContentState>(`${HOST}/contents/unarchive`,
      command,
      { headers: { 'Authorization': `Bearer ${accessToken}` }},
    )

    return extractData(response)
  }

  export async function createAiDescription(
    accessToken : string | undefined,
    command     : Models.CreateAiDescriptionCommand,
  ) {
    const response = await axios.post<void>(`${HOST}/contents/generate-desc`,
      command,
      { headers: { 'Authorization': `Bearer ${accessToken}` }},
    )

    return extractData(response)
  }
}

export default Apis