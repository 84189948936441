import React from "react";
import { RemoteJob } from "./RemoteJobModels";
import { getAllRemoteJobs, getRemoteJobDetails } from "./RemoteJobApis";
import { useAuth } from "../providers/AuthProvider";

const RemoteJobContext = React.createContext<RemoteJobContextType>(null!);

export function RemoteJobProvider({ children }: { children: React.ReactNode }) {
    const auth = useAuth();

    const [remoteJobsById, setRemoteJobsById] = React.useState<ReadonlyMap<string, RemoteJob>>();
    const watchTask = (remoteJob: RemoteJob) => {
        console.debug('Task added to watch', remoteJob.id);
        setRemoteJobsById(new Map(remoteJobsById).set(remoteJob.id, remoteJob));
    }

    React.useEffect(() => {
        if (remoteJobsById === undefined) {
            initializeRemoteJobs(auth.accessTokenPromise, setRemoteJobsById);
            return () => {};
        } else if (shouldCheckRemoteJobs(remoteJobsById)) {
            const interval = setInterval(() => checkRemoteJobs(auth.accessTokenPromise, remoteJobsById, setRemoteJobsById), 2500);
            return () => clearInterval(interval);
        } else {
            return () => {};
        }
    }, [remoteJobsById, auth.accessTokenPromise]);

    return <RemoteJobContext.Provider value={{ remoteJobsById: remoteJobsById || new Map(), watchTask }}>{children}</RemoteJobContext.Provider>;
}

export function useRemoteJob() {
    return React.useContext(RemoteJobContext);
}

interface RemoteJobContextType {
    remoteJobsById: ReadonlyMap<string, RemoteJob>
    watchTask: (remoteJob: RemoteJob) => void;
}

function initializeRemoteJobs(
    accessTokenPromise: Promise<string | undefined>, 
    setRemoteJobsById: (jobs: ReadonlyMap<string, RemoteJob>) => void,
) {
    accessTokenPromise
        .then(accessToken => {
            return getAllRemoteJobs(accessToken);
        })
        .then(response => {
            if (response.status === 200) {
                const remoteJobsById = new Map<string, RemoteJob>(response.data.map(job => [job.id, job]));
                setRemoteJobsById(remoteJobsById);
            } else {
                // ??? fixme
            }
        });
}

function shouldCheckRemoteJobs(remoteJobs: ReadonlyMap<string, RemoteJob> | undefined) {
    if (remoteJobs) {
        return Array.from(remoteJobs.values()).some(remoteJob => remoteJob._type === 'running' || remoteJob._type === 'new');
    } else {
        return false;
    }
}

function checkRemoteJobs(accessTokenPromise: Promise<string | undefined>, tasks: ReadonlyMap<string, RemoteJob>, setTasks: (jobs: ReadonlyMap<string, RemoteJob>) => void) {
    const tasksIdsRunning = Array.from(tasks.values())
        .filter(task => task._type === 'running' || task._type === 'new')
        .map(task => task.id);

    accessTokenPromise
        .then(accessToken => {
            const getRemoteJobDetailsCalls = tasksIdsRunning
                .map(taskId => getRemoteJobDetails(accessToken, taskId))
            return Promise.all(getRemoteJobDetailsCalls);
        })
        .then(responses => {
            let _hasAnythingChanged = false;
            const newTasksById = new Map(tasks);
            responses.forEach(response => {
                if (response.status === 200) {                    
                    if (response.data !== tasks.get(response.data.id)) {
                        _hasAnythingChanged = true;
                        newTasksById.set(response.data.id, response.data);
                        if (response.data._type === 'succeeded' && response.data.children) {
                            response.data.children.forEach(child => newTasksById.set(child.id, child));
                        }
                    }
                } else {
                    // ??? fixme
                }
            });
            if (_hasAnythingChanged) {
                setTasks(newTasksById);
            }
        });
}