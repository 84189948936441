import axios from "axios";
import { CreatePaypalInvoice, InvoiceState } from "./Model_old";
import { useMutation, useQueryClient } from "react-query";
import { NewJob } from "../remote-jobs/RemoteJobModels";
import { HOST } from "../Constants";

export function postBuildAndDeploy(websiteName: string, shouldDeploy: boolean) {
  return axios.post(`${HOST}/builds/build-and-deploy`, 
    { websiteName, shouldDeploy },
    { headers: { 
      'Content-Type': 'application/x-www-form-urlencoded', 
      'Authorization': `Bearer ${window.localStorage.getItem('accessToken') }`,
    }});
}

export function postTaskFetchGoogleDocument(accessToken: string | undefined, googleDocUrl: string, websiteName: string) {
  return axios.post<NewJob>(`${HOST}/posts/fetch-google-doc`, 
    { googleDocUrl: googleDocUrl, websiteName: websiteName },
    { headers: { 
      'Content-Type': 'application/x-www-form-urlencoded', 
      'Authorization': `Bearer ${accessToken}`,
    }});
}

export function postFetchSurferDocument(accessToken: string | undefined, websiteName: string, surferFiles: FileList) {
  let surferFilesObject = {}
  for(let i = 0; i < surferFiles.length; i++) {
    const key = `surferFile[${i}]`
    surferFilesObject = Object.assign(surferFilesObject, {[key]: surferFiles[i]})
  }

  return axios.post<NewJob>(`${HOST}/posts/create-from-surfer`,
    { websiteName, ...surferFilesObject},
    { headers: { 
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${accessToken}`,
    }})
}

export function deleteImage(accessToken: string | undefined, imageId: string) {
  return axios.delete<NewJob>(`${HOST}/images/delete/${imageId}`,
    { headers: {
      'Authorization': `Bearer ${accessToken}`,
    }});
}

export function longTyetye(accessToken: string | undefined) {
  return axios.post<NewJob>(`${HOST}/long-tyetye/atyus`,
    { headers: {
      'Authorization': `Bearer ${accessToken}`,
    }});
}

export function finalizeImage(accessToken: string | undefined, imageId: string) {
  return axios.post(`${HOST}/images/finalize/`,
    { imageId },
    { headers: {
      'Content-Type': 'application/x-www-form-urlencoded', 
      'Authorization': `Bearer ${accessToken}`,
    }});
}

export function postNewImage(imageAlt: string, imageCopyrightHtml: string, imageFile: File, imageAlias?: string) {
  return axios.post(`${HOST}/images/upload`, 
    { imageAlt, imageCopyrightHtml, imageFile, imageAlias},
    { headers: { 
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${window.localStorage.getItem('accessToken') }`,
    }});
}

export function useCreateUnpaidPaypalInvoice(onSuccess: () => void) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (command: CreatePaypalInvoice) => {
      return axios.post(`${HOST}/invoices/paypal/`, command);
    },
    onSuccess: () => { 
      queryClient.invalidateQueries({ queryKey: 'invoice-states' });
      onSuccess();
    },
  });
}

export function getInvoiceStates(): Promise<ReadonlyArray<InvoiceState>> {
  return axios
    .get(`${HOST}/invoices/`)
    .then(response => response.data as ReadonlyArray<InvoiceState>);
}

export function usePostRecordPayment(onSuccess: () => void) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (invoiceId: string) => {
      return axios.post(`${HOST}/invoices/payment/`, {invoiceId: invoiceId});
    },
    onSuccess: () => { 
      queryClient.invalidateQueries({ queryKey: 'invoice-states' });
      onSuccess();
    },
  });
}