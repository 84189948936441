import React from "react";
import { postLogin, postLogout, postRefreshToken } from "../apis/auth/AuthApis";

interface AuthContextType {
    tokens?: AuthTokens;
    accessTokenPromise: Promise<string | undefined>,
    login: (username: string, password: string, onAuthorized: VoidFunction, onUnauthorized: VoidFunction) => void;
    logout: (onAuthorized: VoidFunction, onUnauthorized: VoidFunction) => void;
}
  
const AuthContext = React.createContext<AuthContextType>(null!);
  
export function AuthProvider({ children }: { children: React.ReactNode }) {
    
    const [tokens, setTokens] = React.useState(getAuthTokensFromLocalStorage());
    const [accessTokenPromise, setAccessTokenPromise] = React.useState(new Promise<string | undefined>(resolve => resolve(tokens?.accessToken)));
  
    function login(username: string, password: string, onAuthorized: VoidFunction, onUnauthorized: VoidFunction) {
      return postLogin(username, password) // fixme should this be set as accessTokenPromise immediately instead of waiting for the response?
        .then(response => {
            if (response.status === 200) {
                setAccessTokenPromise(new Promise(r => r(response.data.accessToken)));
                saveAuthTokensToLocalStorage(response.data);
                setTokens(response.data);

                onAuthorized()
            } else {
                onUnauthorized()
            }
        })
        .catch(error => {
            console.error(error)
        })
    }
  
    function logout(onAuthorized: VoidFunction, onUnauthorized: VoidFunction) {
      return accessTokenPromise // fixme should this be set as accessTokenPromise immediately instead of waiting for the response?
        .then(accessToken => {
            return postLogout(accessToken)
        })
        .then(response => {
            if (response.status === 200) {
                onAuthorized()
            } else {
                onUnauthorized()
            }
        })
        .finally(() => {
            setAccessTokenPromise(new Promise((_, reject) => reject('AccessToken is not available')))
            setTokens(undefined)
            clearAuthTokensFromLocalStorage()
        })
    }
    
    const REFRESH_TOKEN_VALIDITY_TOLERANCE_IN_MS = 15 * 1000; // 15 seconds
    function refreshTokensIfNeededSynced(tokensInState: AuthTokens) {

        window.navigator.locks.request('LvVcYFs1T4', async _ => {
            const tokenInLocalStorage = getAuthTokensFromLocalStorage();
            
            // check if the in state token is the same as the one in the storage, if not some other tab updated it
            if (tokenInLocalStorage?.accessToken === tokensInState.accessToken) {
                return refreshTokensIfNeededUnsafe(tokensInState);
            } else {
                return new Promise(r => {
                    setTokens(tokenInLocalStorage);
                    setAccessTokenPromise(new Promise(r => r(tokenInLocalStorage?.accessToken)));
                    r({});
                });
            }
        });
    };

    function refreshTokensIfNeededUnsafe(tokens: AuthTokens): Promise<void> {

        const nowInMs = new Date().getTime();
        const isAccessTokenExpired = tokens.accessTokenValidUntil - REFRESH_TOKEN_VALIDITY_TOLERANCE_IN_MS < nowInMs;
        if (isAccessTokenExpired) {
            const isRefreshTokenExpired = tokens.refreshTokenValidUntil - REFRESH_TOKEN_VALIDITY_TOLERANCE_IN_MS < nowInMs;
            if (isRefreshTokenExpired) {
                // remove tokens, new login is required
                // fixme what then?
                return new Promise(r => r());
            } else {
                return performTokenRefresh(tokens.refreshToken);
            }
        } else {
            // do nothing
            return new Promise(r => r());
        }
    };

    function performTokenRefresh(refreshToken: string) {
        const refreshPromise = postRefreshToken(refreshToken)
            .then(response => {
                if(response.status === 200) {
                    return response.data;
                } else {
                    return undefined;
                }
            });

        setAccessTokenPromise(refreshPromise.then(maybeTokens => {
            if (maybeTokens?.accessToken) { 
                return maybeTokens?.accessToken;
            } else {
                throw new Error('AccessToken is not available');
            }
        }));


        return refreshPromise
            .then(tokens => {
                if (tokens !== undefined) {
                    setTokens(tokens);
                    saveAuthTokensToLocalStorage(tokens);
                } else {
                    setTokens(undefined);
                    clearAuthTokensFromLocalStorage();
                }
            });
    }

    function getAuthTokensFromLocalStorage(): AuthTokens | undefined {
        const accessToken                  = window.localStorage.getItem("accessToken");
        const refreshToken                 = window.localStorage.getItem("refreshToken");
        const accessTokenValidUntilString  = window.localStorage.getItem("accessTokenValidUntil");
        const refreshTokenValidUntilString = window.localStorage.getItem("refreshTokenValidUntil");
      
        const accessTokenValidUntil  = accessTokenValidUntilString ? Number.parseInt(accessTokenValidUntilString) : undefined;
        const refreshTokenValidUntil = refreshTokenValidUntilString ? Number.parseInt(refreshTokenValidUntilString) : undefined;
      
        const tokensAreStored = accessToken && refreshToken && accessTokenValidUntil !== undefined && refreshTokenValidUntil !== undefined;
        if (tokensAreStored) {
            return {
                accessToken,
                refreshToken,
                accessTokenValidUntil,
                refreshTokenValidUntil,
            };
        } else {
            return undefined;
        }
    }

    // This is where the token refresh is triggered.
    React.useEffect(() => {

        if (tokens) {
            const nowInMs = new Date().getTime();
            const nextRefreshTimeoutInMs = tokens.accessTokenValidUntil - REFRESH_TOKEN_VALIDITY_TOLERANCE_IN_MS - nowInMs;
            const timeoutId = setTimeout(() => refreshTokensIfNeededSynced(tokens), Math.max(nextRefreshTimeoutInMs, 0));
            return () => clearTimeout(timeoutId);
        } else {
            // do nothing
        }
    }, [tokens, refreshTokensIfNeededSynced, REFRESH_TOKEN_VALIDITY_TOLERANCE_IN_MS]);

    return <AuthContext.Provider value={{ tokens, accessTokenPromise, login, logout }}>{children}</AuthContext.Provider>;
}

      
function saveAuthTokensToLocalStorage(tokens: AuthTokens): void {
    window.localStorage.setItem("accessToken", tokens.accessToken);
    window.localStorage.setItem("refreshToken", tokens.refreshToken);
    window.localStorage.setItem("accessTokenValidUntil", tokens.accessTokenValidUntil.toFixed());
    window.localStorage.setItem("refreshTokenValidUntil", tokens.refreshTokenValidUntil.toFixed());
}
  
function clearAuthTokensFromLocalStorage(): void {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");
    window.localStorage.removeItem("accessTokenValidUntil");
    window.localStorage.removeItem("refreshTokenValidUntil");
}

export function useAuth() {
    return React.useContext(AuthContext);
}

export type AuthTokens = {
    accessToken: string,
    refreshToken: string,
    accessTokenValidUntil: number,
    refreshTokenValidUntil: number,
}