import React from "react";
import {AlertColor} from "@mui/material/Alert/Alert";
import Alert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";

/**
 * Provides access to a global `Snackback` instance.
 */
export function useSnackbar() {
  return React.useContext(SnackbarContext);
}

export function SnackbarProvider({children}: { children: React.ReactNode }) {
  const [snackbar, setSnackbar] = React.useState<SnackbarState>({open: false, msg: "", severity: "info"});

  const showSnackbar = (msg: string, severity: AlertColor) => {
    setSnackbar({open: true, msg, severity});
  };

  const hideSnackbar = () => {
    setSnackbar({...snackbar, open: false});
  };

  const showError = (msg: string) => showSnackbar(msg, "error");

  const showSuccess = (msg: string) => showSnackbar(msg, "success");

  const showWarning = (msg: string) => showSnackbar(msg, "warning");

  return (
    <SnackbarContext.Provider value={{showError, showSuccess, showWarning}}>
      <Snackbar
        anchorOrigin={{vertical: "top", horizontal: "center"}}
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={() => hideSnackbar()}
      >
        <Alert
          variant="filled"
          elevation={6}
          severity={snackbar.severity}
        >
          {snackbar.msg}
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
}

const DefaultSnackbarContext: SnackbarContextType = {
  showError: (msg: string) => {
    console.error(msg);
  },
  showSuccess: (msg: string) => {
    console.info(msg);
  },
  showWarning: (msg: string) => {
    console.warn(msg);
  },
}

const SnackbarContext = React.createContext<SnackbarContextType>(DefaultSnackbarContext);

interface SnackbarContextType {
  showError:   (msg: string) => void
  showSuccess: (msg: string) => void
  showWarning: (msg: string) => void
}

type SnackbarState = {
  open:     boolean
  msg:      string
  severity: AlertColor
}